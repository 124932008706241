/**
 * This refers to the Redux state objects that are often passed around via the various Ax components.
 */

import { ColumnFormat } from 'common/types/viewColumn';
import { Vif } from 'common/visualizations/vif';

/* eslint @typescript-eslint/ban-types: "warn" */
export interface AuthoringWorkflowState {
  metadataCollection: object;
  vifAuthoring: VifAuthoring;
}

/**
 * This is the vifAuthoring object that contains all of the potential vifs
 * to be used during the Authoring Experience as well as a common configuration object
 * We should eventually fill this out.
 */
export interface VifAuthoring {
  vifs: {
    agTable: any;
    initialVif: Vif;
  };
}

export interface Aggregation {
  title: string;
  value: string;
}

export interface ConditionalConfig {
  expression: Expression;
  style: FormatStyle;
}

export interface TableColumnFormat {
  colorPalette: string;
  colorPaletteType: string;
  columnName: string;
  conditionStyle?: FormatStyle;
  conditionalConfig?: ConditionalConfig[];
  conditionalType: string;
  displayName: string;
  expression: Expression;
  format: ColumnFormat;
  isAlignHeader: boolean;
  isFormatSubtotal?: boolean;
  isFormatTotal: boolean;
  isFormatValue: boolean;
  style: FormatStyle;
}

export interface RowFormat {
  colorPalette: string;
  colorPaletteType: string;
  columnName: string;
  conditionStyle: FormatStyle;
  conditionalConfig?: ConditionalConfig[];
  conditionalType: string;
  expression: Expression;
  isFormatSubtotal?: boolean;
  isFormatTotal?: boolean;
  isFormatValue?: boolean;
  style: FormatStyle;
}

export interface ArgumentOption {
  end: string;
  start: string;
  value: string | number;
}

export enum FontStyleTypes {
  isBold = 'isBold',
  isItalic = 'isItalic',
  isWrapped = 'isWrapped'
}

export type FontStyle = {
  [key in FontStyleTypes]: boolean;
};

export interface FormatStyle {
  backgroundColor: string;
  color?: string;
  fontStyle?: FontStyle;
  fontWeight?: 'bold' | 'italic';
  textColor: string;
  textFont: string;
}

export interface Expression {
  arguments: ArgumentOption;
  function: string;
}
