import { Both, Either, factorOption, iif, buildBoth } from 'common/either';
import { FeatureFlags } from 'common/feature_flags';
import { FunCall, TypedSoQLFunCall, Expr, TypedExpr } from 'common/types/soql';
import { Eexpr, EexprNA } from '../types';
import { Option, option } from 'ts-option';
import { Query } from '../redux/store';
import { analysisSuccess, compilationSuccess } from '../lib/selectors';
import {
  QueryAnalysisResult, QueryAnalysisSucceeded,
  QueryCompilationSucceeded, QueryCompilationResult
} from 'common/types/compiler';

export const usingSoda3EC = () =>
    FeatureFlags.valueOrDefault('soda3_ec', false);

export const whichAnalyzer = iif(usingSoda3EC);
export const eitherForTest = buildBoth(usingSoda3EC);

export type TransitionSuccessType = Both<QueryCompilationSucceeded, Option<QueryAnalysisSucceeded>>;
export const buildSucceeded = (c: QueryCompilationSucceeded, a?: QueryAnalysisSucceeded) =>
  buildBoth(usingSoda3EC)(() => c, () => option(a))();
export const buildSuccessOption = (q: Query): Option<TransitionSuccessType> => compilationSuccess(q.compilationResult).map(cr => {
  const ar = analysisSuccess(q.analysisResult).orUndefined;
  return buildSucceeded(cr, ar);
});

export const createUpdateContents = (
  eitherEexpr: Either<Eexpr<FunCall, TypedSoQLFunCall>, EexprNA<TypedSoQLFunCall>>,
  otherArgs: Either<Expr[], TypedExpr[]>
) =>
  eitherEexpr.map(
    eexpr => ({
      ...eexpr.untyped,
      args: [eexpr.untyped.args[0], ...otherArgs.left]
    }),
    eexpr => ({
      ...eexpr.expr,
      args: [eexpr.expr.args[0], ...otherArgs.right]
    })
  );
