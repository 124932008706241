import './CalendarDateParameter.scss';
// Vendor Imports
import bindAll from 'lodash/bindAll';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import moment from 'moment';
import React, { Component } from 'react';
import classNames from 'classnames';
import {
  ForgeDatePicker,
  ForgeOption,
  ForgeRadio,
  ForgeSelect,
  ForgeTextField
} from '@tylertech/forge-react';

// Project Imports
import I18n from 'common/i18n';
import FilterFooter, { FilterFooterProps } from '../FilterFooter';
import FeatureFlags from 'common/feature_flags';

import { RELATIVE_FILTERS, RELATIVE_FILTER_VALUES } from 'common/dates';
import { assertIsNotNil } from 'common/assertions';
import { ParameterConfiguration } from 'common/types/reportFilters';

// Constants
const scope = 'shared.components.filter_bar.calendar_date_filter';
const DATE_FORMAT = 'YYYY-MM-DDT00:00:00';
export const PARAMETER_TYPES = {
  DAY: 'day',
  RELATIVE_DAY: 'relative_day'
};

interface CalendarDateParameterProps {
  parameter: ParameterConfiguration;
  onUpdate: (value: string) => void;
  onReset: () => void;
}

interface CalendarDateParameterState {
  date: string;
  relativeDate: {
    period: string;
    value: number;
    type: string;
  };
  calendarDateParameterType: string;
}

class CalendarDateParameter extends Component<CalendarDateParameterProps, CalendarDateParameterState> {
  private dateParameterRef = React.createRef<HTMLDivElement>();

  constructor(props: CalendarDateParameterProps) {
    super(props);

    bindAll(this, [
      'applyParameterOverride',
      'getInitialState',
      'calculateDate',
      'renderSingleSelectDayPicker',
      'onChangeDate',
      'shouldDisableApply',
      'resetParameterOverride',
      'onChangeSelectedRadionOption'
    ]);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const { parameter } = this.props;

    let state;
    if (this.isRelativeValue(parameter)) {
      state = {
        date: moment().startOf('day').format(DATE_FORMAT),
        relativeDate: {
          ...get(RELATIVE_FILTER_VALUES, RELATIVE_FILTERS[parameter.overrideValue.toUpperCase()])
        },
        calendarDateParameterType: PARAMETER_TYPES.RELATIVE_DAY
      };
    } else {
      state = {
        date: parameter.overrideValue,
        relativeDate: {
          ...get(RELATIVE_FILTER_VALUES, RELATIVE_FILTERS.TODAY)
        },
        calendarDateParameterType: PARAMETER_TYPES.DAY
      };
    }

    return state;
  }

  isRelativeValue(parameter: ParameterConfiguration) {
    if (
      parameter.overrideValue === RELATIVE_FILTERS.TODAY ||
      parameter.overrideValue === RELATIVE_FILTERS.YESTERDAY
    ) {
      return true;
    }

    return false;
  }

  applyParameterOverride() {
    const { onUpdate } = this.props;
    const { date, relativeDate, calendarDateParameterType } = this.state;

    onUpdate(calendarDateParameterType === PARAMETER_TYPES.DAY ? date : relativeDate.type);
  }

  resetParameterOverride() {
    const { onReset } = this.props;

    onReset();
  }

  shouldDisableApply() {
    // Compare the parameter date value returned by initial state to the current state
    // If they are the same disable apply button
    return isEqual(this.getInitialState(), this.state);
  }

  calculateDate(value?: string): moment.Moment {
    if (value === RELATIVE_FILTERS.TODAY) {
      return moment().startOf('day');
    } else if (value === RELATIVE_FILTERS.YESTERDAY) {
      return moment().subtract(1, 'days').startOf('day');
    } else {
      return moment(value);
    }
  }

  onChangeDate(date?: string) {
    assertIsNotNil(date);
    const alteredDate = this.calculateDate(date).format(DATE_FORMAT);

    this.setState({ date: alteredDate });
  }

  onChangeRelativeDate(relativeDate: string) {
    const test = get(RELATIVE_FILTER_VALUES, relativeDate);
    this.setState({ relativeDate: { ...get(RELATIVE_FILTER_VALUES, relativeDate) } });
  }

  onChangeSelectedRadionOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ calendarDateParameterType: event.target.value });
  };

  renderSingleSelectDayPicker() {
    const { calendarDateParameterType } = this.state;
    const startDate = this.calculateDate(this.state.date).format(DATE_FORMAT);

    const datePickerProps = {
      disabled: calendarDateParameterType === PARAMETER_TYPES.RELATIVE_DAY,
      value: startDate,
      'on-forge-date-picker-change': (event: CustomEvent) => {
        this.onChangeDate(event.detail);
      }
    };

    return (
      <div className="single-select-day-picker-parameter">
        <ForgeDatePicker {...datePickerProps}>
          <ForgeTextField>
            <input type="text" id="parameter-input-date-picker" />
          </ForgeTextField>
        </ForgeDatePicker>
      </div>
    );
  }

  renderRelativeDateOptions() {
    const { calendarDateParameterType, relativeDate } = this.state;

    const selectProps = {
      disabled: calendarDateParameterType === PARAMETER_TYPES.DAY,
      label: I18n.t('relative_date_label', { scope }),
      'on-change': (event: CustomEvent) => this.onChangeRelativeDate(event.detail),
      value: relativeDate.type,
      popupClasses: 'forge-select__popup'
    };

    return (
      <div className="relative-period">
        <ForgeSelect {...selectProps}>
          <ForgeOption value={RELATIVE_FILTERS.TODAY} data-testId="parameter-relative-date-today">
            {I18n.t('relative_periods.today', { scope })}
          </ForgeOption>
          <ForgeOption value={RELATIVE_FILTERS.YESTERDAY} data-testId="parameter-relative-date-yesterday">
            {I18n.t('relative_periods.yesterday', { scope })}
          </ForgeOption>
        </ForgeSelect>
      </div>
    );
  }

  render() {
    const { calendarDateParameterType } = this.state;
    const enableParameterDropdownsInStories = FeatureFlags.valueOrDefault(
      'enable_parameter_dropdowns_in_stories',
      false
    );
    const footerProps: FilterFooterProps = {
      disableApplyFilter: this.shouldDisableApply(),
      onClickApply: this.applyParameterOverride,
      onClickReset: enableParameterDropdownsInStories ? this.resetParameterOverride : undefined,
      // Parameters will always be read only as they cannot be removed from the report
      isReadOnly: true
    };

    return (
      <div className={classNames('filter-controls', 'calendar-date-filter')} ref={this.dateParameterRef}>
        <div className="range-filter-container">
          <div role="radiogroup" className="radio-button-group" aria-label="parameter selection">
            <ForgeRadio>
              <input
                type="radio"
                id="single-day-picker"
                checked={calendarDateParameterType === PARAMETER_TYPES.DAY}
                name="day-selector-radio"
                value={PARAMETER_TYPES.DAY}
                onChange={this.onChangeSelectedRadionOption}
              ></input>
              <label>{this.renderSingleSelectDayPicker()}</label>
            </ForgeRadio>
            <ForgeRadio>
              <input
                type="radio"
                id="relative-day-picker"
                checked={calendarDateParameterType !== PARAMETER_TYPES.DAY}
                name="day-selector-radio"
                value={PARAMETER_TYPES.RELATIVE_DAY}
                onChange={this.onChangeSelectedRadionOption}
              />
              <label>{this.renderRelativeDateOptions()}</label>
            </ForgeRadio>
          </div>
        </div>
        <FilterFooter {...footerProps} />
      </div>
    );
  }
}

export default CalendarDateParameter;
