import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CatalogConfig } from '../types';
import { RootState } from './store';
import { fetchConfigsByType } from 'common/core/configurations';
import { getCurrentDomain } from 'common/currentDomain';
import { catalogConfigToSortBy, catalogConfigToFederationFilter } from 'browse3/helpers';
import { AssetSearchOptions } from 'browse3/types';

export interface CatalogConfigState {
  config: CatalogConfig;
  configQueryParams: AssetSearchOptions;
  configLoaded: boolean;
  federation_filter: string;
}

const initialState: CatalogConfigState = {
  config: {
    id: 0,
    name: '',
    type: '',
    default: false,
    properties: []
  },
  configQueryParams: {},
  configLoaded: false,
  federation_filter: ''
};

export const fetchCatalogConfig = createAsyncThunk('catalog/getCatalogConfig', async (thunkAPI) => {
  const response = await fetchConfigsByType(getCurrentDomain(), 'catalog');
  return response;
});

export const CatalogConfigSlice = createSlice({
  name: 'catalogConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCatalogConfig.fulfilled, (state, action) => {
      // there should never be more than one result with default==true, so we break when we find the first one
      // but we need to loop because the response is an array of config objects
      for (let i = 0; i < action.payload.length; i++) {
        const result = action.payload[i];
        if (result.default) {
          state.config.id = result.id;
          state.config.name = result.name;
          state.config.type = result.type;
          state.config.default = result.default;
          state.config.properties = result.properties;
          break;
        }
      }
      state.configQueryParams = catalogConfigToSortBy(state.config);
      state.federation_filter = catalogConfigToFederationFilter(state.config);
      state.configLoaded = true;
    });
  }
});

export const getCatalogConfig = (state: RootState) => {
  return state.catalogConfig;
};

export default CatalogConfigSlice.reducer;
