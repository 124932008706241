import React, { useEffect, useState, useRef, useCallback } from 'react';
import { GridApi } from '@ag-grid-community/core';

import { AG_SOQL_EXPORT_QUERY_LIMIT } from 'common/visualizations/helpers/TableDataHelpers';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { ExportData } from 'common/visualizations/views/agGridReact/types';
import { OrderConfig } from 'common/visualizations/vif';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import { AgGridDataProcessor } from '../processors/GridDataProcessor';
import { Dataset, PreviewSettings, ReportMetadata, WorkerOutputMessage } from '../types';
import { PDFPreview } from './PdfPreview';
import { Settings } from './Settings';
import { usePdfWorker } from './usePdfWorker';
import { PRINT_PREVIEW_FONT_SLIDER_CONFIG } from '../templates/section/defaultStyles';

interface PreviewPaneProps {
  columnFormats: { [key: string]: TableColumnFormat };
  dataset: Dataset;
  getExportData: (selectedFiltered: boolean) => Promise<ExportData>;
  gridApi: GridApi;
  nonStandardAggregations: ColumnAggregation[] | null;
  onError?: (error: string) => void;
  reportMetadata: ReportMetadata;
  showSubTotal: boolean;
  showTotal: boolean;
  vifOrderConfig: OrderConfig[];
}

export type PREVIEW_STATUS = 'initializing' | 'loading' | 'working' | 'tooManyRows' | 'error' | 'done';

const getDefaultSettings = (gridApi: GridApi): PreviewSettings => ({
  title: '',
  pageSize: 'Letter',
  orientation: 'landscape',
  scale: 1,
  monetaryUnit: 'thousands',
  template: gridApi.getRowGroupColumns().length > 0 ? 'grouped-aggregate' : 'transaction-table',
  pageBreak: 'standard',
  textOverflow: 'linebreak',
  columnWidth: 'bestfit',
  fontSize: PRINT_PREVIEW_FONT_SLIDER_CONFIG.DEFAULT
});

const dataProcessor = new AgGridDataProcessor();
export const PreviewPane: React.FC<PreviewPaneProps> = ({
  columnFormats,
  dataset,
  getExportData,
  gridApi,
  nonStandardAggregations,
  onError,
  reportMetadata,
  showSubTotal,
  showTotal,
  vifOrderConfig
}) => {
  const [settings, setSettings] = useState<PreviewSettings>({ ...getDefaultSettings(gridApi) });
  const currentBlobUrl = useRef<string | null>(null);
  const [pdfData, setPdfData] = useState<string | null>(null);
  const [status, setStatus] = useState<PREVIEW_STATUS>('initializing');
  const onMessage = useCallback(
    (message: MessageEvent<WorkerOutputMessage>) => {
      switch (message.data.type) {
        case 'preview':
          if (currentBlobUrl.current) {
            URL.revokeObjectURL(currentBlobUrl.current);
          }
          const blobUrl = URL.createObjectURL(message.data.data.content);
          currentBlobUrl.current = blobUrl;
          setPdfData(blobUrl);
          setStatus('done');
          break;
        case 'error':
          onError?.(message.data.error);
          setStatus('error');
          break;
      }
    },
    [onError, setStatus]
  );
  const worker = usePdfWorker({ onError, onMessage });

  useEffect(() => {
    if (!worker) {
      return;
    }
    setStatus('loading');
    const extraOptions = {
      dataset,
      vifOrderConfig,
      columnFormats,
      nonStandardAggregations,
      showSubTotal,
      showTotal
    };
    dataProcessor
      .processGridData(gridApi, getExportData, extraOptions)
      .then((processedData) => {
        // We limit and fetch only `AG_SOQL_EXPORT_QUERY_LIMIT` no of rows. So
        // if we get the same number of rows are fetched, we conclude that there
        // are too many records to print or preview.
        if (processedData.processedRowCount >= AG_SOQL_EXPORT_QUERY_LIMIT) {
          setStatus('tooManyRows');
          return;
        }
        if (worker) {
          setStatus('working');
          worker.postMessage({
            type: 'generatePreview',
            data: processedData,
            settings,
            reportMetadata
          });
        } else {
          setStatus('error');
          console.error('Worker is no longer available');
        }
      })
      .catch((error) => {
        setStatus('error');
        console.error('Error processing grid data:', error);
      });
  }, [
    columnFormats,
    dataset,
    getExportData,
    gridApi,
    nonStandardAggregations,
    reportMetadata,
    settings,
    showSubTotal,
    showTotal,
    vifOrderConfig,
    worker
  ]);

  return (
    <>
      <Settings settings={settings} onChange={setSettings} />
      <PDFPreview status={status} pdfData={pdfData} />
    </>
  );
};
