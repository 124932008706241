import { sortBy, uniqBy } from 'lodash';
import { GridApi } from '@ag-grid-community/core';

import DataTypeFormatter from 'common/DataTypeFormatter';
import { ExportData } from 'common/visualizations/views/agGridReact/types';
import { OrderConfig } from 'common/visualizations/vif';
import { GROUP_COLUMN_PREFIX } from 'common/visualizations/views/agGridReact/Constants';

import { ExtraOptions, GridDataProcessor, GridDataProcessorResult, TableColumn } from '../types';
import { NormalRow } from './type';
import { parseRows } from './gridDataParser';
import { groupRows } from './gridDataGrouper';
import { formatRows } from './gridDataFormatter';
import { buildTotalsRow } from './gridDataAggregator';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';

// Note: Retrieving and grouping/formatting data can also be moved to the worker for efficiency.
// Currently, we retrieve 10K records, format/group them in main thread.
// Serialize and send it to worker thread. It again deserializes it (JSON) which is a waste.
// export class GridDataProcessor implements IGridDataProcessor {
export class AgGridDataProcessor implements GridDataProcessor {
  async processGridData(
    gridApi: GridApi,
    getExportData: (selectedFiltered: boolean) => Promise<ExportData>,
    extraOptions: ExtraOptions
  ): Promise<GridDataProcessorResult> {
    const exportData = await getExportData(true);
    const tableColumns = this.buildTableColumns(gridApi, exportData, extraOptions);
    const orderConfigs = uniqBy(
      extraOptions.vifOrderConfig.map((orderConfig: OrderConfig) => ({
        ...orderConfig,
        columnName: orderConfig.columnName.replace(`${GROUP_COLUMN_PREFIX}-`, '')
      })),
      'columnName'
    );
    const parsedRows = parseRows(exportData.rows as NormalRow[], tableColumns);
    const groupedRows = groupRows(parsedRows, tableColumns, orderConfigs);
    const rowData = formatRows({
      rowData: extraOptions.showTotal
        ? [...groupedRows, buildTotalsRow(groupedRows, tableColumns)]
        : groupedRows,
      tableColumns,
      viewColumns: exportData.columns,
      extraOptions,
      gridApi
    });

    return {
      rowData,
      tableColumns,
      tableDef: {
        rowHeight: Number(gridApi.getGridOption('rowHeight')) || 10
      },
      processedRowCount: exportData.rows.length,
      forgeThemeColorConfig: getForgeThemeColorConfig()
    };
  }

  private buildTableColumns(
    gridApi: GridApi,
    exportData: ExportData,
    extraOptions: ExtraOptions
  ): TableColumn[] {
    const groupedFields = gridApi.getRowGroupColumns().map((col) => col.getColId());
    const nonGroupedFields = gridApi
      .getAllDisplayedColumns()
      .map((col) => col.getColId())
      .filter((id) => !id.startsWith(GROUP_COLUMN_PREFIX));
    const visibleColumnIds = [...groupedFields, ...nonGroupedFields];

    return sortBy(exportData.columns, (col) => visibleColumnIds.indexOf(col.fieldName))
      .filter((col) => visibleColumnIds.includes(col.fieldName))
      .map((col) => {
        const gridCol = gridApi.getColumn(col.fieldName);
        const colDef = gridCol?.getColDef();
        const columnFormat: TableColumnFormat | undefined = extraOptions.columnFormats[col.fieldName];
        const textAlign = DataTypeFormatter.getCellAlignment(col);
        const align = columnFormat?.format?.align;
        const isAlign = columnFormat?.isFormatValue ?? true;
        return {
          field: col.fieldName,
          displayName: columnFormat?.displayName || col.name || col.fieldName,
          type: col.dataTypeName,
          aggFunc: colDef?.aggFunc?.toString(),
          width: gridCol?.getActualWidth(),
          isGrouped: groupedFields.includes(col.fieldName),
          align: isAlign ? align || textAlign : textAlign
        };
      });
  }
}

const getForgeThemeColorConfig = () => {
  return {
    mdcThemeBackground: getCSSVariableValue('--mdc-theme-background'),
    mdcThemeOnSecondary: getCSSVariableValue('--mdc-theme-primary'),
    forgeThemeBorderColor: getCSSVariableValue('--forge-theme-border-color'),
    mdcThemeOnPrimary: getCSSVariableValue('--mdc-theme-on-primary'),
    pdfPreviewHeaderBackground: getCSSVariableValue('--pdf-preview-header-background')
  };
};

const getCSSVariableValue = (variableName: string): string => {
  const style = window.getComputedStyle(document.body);
  return style.getPropertyValue(variableName);
};
