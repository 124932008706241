import { compact, isUndefined, reduce, values } from 'lodash';

import { fetchJsonWithDefaultHeaders, fetchJsonWithFederation } from 'common/http';
import FeatureFlags from 'common/feature_flags';
import { MAX_URL_SIZE, SODA_2_MIN_QUERY_POST_SIZE } from 'common/utilities/Constants';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { toTypedOverrideParams, toV3OverrideParams } from 'common/core/client_context_variables';
import { BinaryTree, Expr } from 'common/types/soql';
import { SoQLV3APIOptions } from 'common/types/soql/api';

import { buildLiteral } from './expr';
import functions, { wfOver } from './function';
import { buildPrerenderedExpression, buildSelect, buildStarSelection } from './select';
import { buildDistinctOn } from './distinct';
import { buildOrderBy } from './orderBy';
import {
  buildInnerJoin,
  buildOuterJoin,
  buildLeftJoin,
  buildRightJoin,
  buildFullJoin,
  buildSubSelect,
  decorateJoinWithLateral
} from './join';
import { QueryParts, buildQuery } from './query/build';
import compounds, { connectQueries } from './query/compound';
import { renderQuery } from './query/render';
import { buildTableName } from './tableName';
import { buildQualifiedViewColumn, encodeStringAsColumnRef } from './util';
import { buildWindowFunction } from './windowFunction';

const connectExprs = (connector: typeof functions.and, clauses: Expr[]) =>
  reduce(clauses.slice(1), (nested, clause) => connector(nested, clause), clauses[0]);
/**
 * Concatenates Expressions with ANDs; easier than doing this manually.
 * @param clauses Expressions that you wish to AND together, given as arguments.
 */
const buildWhereAnd = (...clauses: (Expr | undefined)[]) => connectExprs(functions.and, compact(clauses));
/**
 * Concatenates Expressions with ORs; easier than doing this manually.
 * @param clauses Expressions that you wish to OR together, given as arguments.
 */
const buildWhereOr = (...clauses: (Expr | undefined)[]) => connectExprs(functions.or, compact(clauses));

/**
 * Construct a system Star Selection object, e.g. `SELECT :* (EXCEPT a, b, c)`.
 *
 * @remarks
 * You should not need this function; it is a smell in custom-designed queries.
 *
 * @param exceptions Columns to NOT select for in the star selection.
 */
const buildSystemStar = (exceptions?: Parameters<typeof buildStarSelection>[2]) => buildStarSelection(true, null, exceptions || []);
/**
 * Construct a user Star Selection object, e.g. `SELECT *`; `SELECT @foo.*`.
 *
 * @remarks
 * You should not need this function; it is a smell in custom-designed queries.
 *
 * @param qualifier The qualifier being used on a user star selection.
 * @param exceptions Columns to NOT select for in the star selection.
 */
const buildUserStar = (qualifier?: Parameters<typeof buildStarSelection>[1], exceptions?: Parameters<typeof buildStarSelection>[2]) => buildStarSelection(false, qualifier, exceptions || []);

/**
 * Construct a SoQL query string from objects defined by the SoQL Builder DSL.
 * @param parts.selects An array of SELECT objects to include. Required if starSelects not present.
 * @param parts.starSelects An array of SELECT * objects to include. Required if selects not present.
 * @param parts.from An object with a fourfour name and an alias.
 * @param parts.distinct {boolean} Whether or not to include the DISTINCT keyword. {@link https://dev.socrata.com/docs/functions/distinct.html}
 * @param parts.joins An array of JOIN objects to include.
 * @param parts.where An Expression to use as the WHERE clause.
 * @param parts.groups An array of GROUP BY objects to include.
 * @param parts.having An Expression to use as the WHERE clause.
 * @param parts.orders An array of ORDER BY objects to include.
 * @param parts.search {string} A string after the SEARCH keyword, for full-text search. Somewhat vestigial.
 * @param parts.limit {number} The number after the LIMIT keyword.
 * @param parts.offset {number} The number after the OFFSET keyword.
 * @see https://github.com/socrata/platform-ui/tree/main/common/soql_builder/
 */
export const composeQuery = (parts: QueryParts | BinaryTree<QueryParts>): string =>
  renderQuery(buildQuery(parts));
export const chainQueries = (...queries: string[]): string => queries.join(' |> ');

/**
 * Concatenates Queries with UNIONs; easier than doing this manually.
 * @param queries Query Parts that you wish to UNION together, given as arguments.
 */
const buildUnion = (...queries: (QueryParts | undefined)[]) =>
  connectQueries(compounds.union, compact(queries));
/**
 * Concatenates Queries with UNION ALLs; easier than doing this manually.
 * @param queries Query Parts that you wish to UNION ALL together, given as arguments.
 */
const buildUnionAll = (...queries: (QueryParts | undefined)[]) =>
  connectQueries(compounds.unionAll, compact(queries));
/**
 * Concatenates Queries with INTERSECTs; easier than doing this manually.
 * @param queries Query Parts that you wish to INTERSECT together, given as arguments.
 */
const buildIntersect = (...queries: (QueryParts | undefined)[]) =>
  connectQueries(compounds.intersect, compact(queries));
/**
 * Concatenates Queries with MINUSs; easier than doing this manually.
 * @param queries Query Parts that you wish to MINUS together, given as arguments.
 */
const buildMinus = (...queries: (QueryParts | undefined)[]) =>
  connectQueries(compounds.minus, compact(queries));
/**
 * Concatenates Queries with PIPEs; easier than doing this manually.
 * @param queries Query Parts that you wish to PIPE together, given as arguments.
 */
const buildChain = (...queries: (QueryParts | undefined)[]) =>
  connectQueries(compounds.chain, compact(queries));

export type ResourceURI = string;
const uriRegex = /(?:https:\/\/.*?)?\/(?:api\/id|resource)\/(?<uid>\w{4}-\w{4})\.(?<format>(?:c|geo)?json)/;
const isResourceURI = (uri: string): uri is ResourceURI => uriRegex.test(uri);

/**
 * A parameterized SoQL query is a function which accepts bindings as an array
 * and outputs the result of composeQuery or chainQueries.
 */
export type ParameterizedSoqlQuery = (...params: any[]) => string;
/**
 * Fetch a JSON response from the Socrata API (version 2) according to the prepared SoQL query.
 * @param resourceUri A string in the shape of 'https://some.domain/resource/four-four.json'.
 * @param preparedStatement A function that returns the result of composeQuery or chainQueries.
 * @param statementParameters An arbitrary array of arguments to pass into the preparedStatement.
 * @param options Additional options to control how the query is executed
 */
export const invokeSoda2 = <T>(
  resourceUri: ResourceURI,
  preparedStatement: ParameterizedSoqlQuery,
  statementParameters: any[],
  options: {
    /** Assumed 2.1 */
    soqlVersion?: number;
    /** @deprecated Please pass clientContextVariables as an array instead */
    clientContextURIEncoded?: string;
    /** Client context variables with override values to be passed along */
    clientContextVariables?: ClientContextVariable[];
    queryTimeout?: number;
  } = {}
): Promise<T> => {
  const { clientContextURIEncoded, clientContextVariables = [], queryTimeout, soqlVersion = 2.1 } = options;
  const regexMatches = resourceUri.match(uriRegex);
  if (isResourceURI(resourceUri) && regexMatches) {
    const queryUid = regexMatches[1];
    const queryFormat = regexMatches[2];
    const query = preparedStatement(...statementParameters);
    const version = soqlVersion === 2.1 ? undefined : soqlVersion;

    // These query params are used in both get and post queries
    const sharedQueryParams = {
      $$version: version,
      $$query_timeout_seconds: queryTimeout || undefined,
      ...toTypedOverrideParams(clientContextVariables)
    };

    const makeQuery = (queryParams: object) => {
      return reduce(
        queryParams,
        (result, value, key) => {
          if (!isUndefined(value)) {
            result.push(`${key}=${encodeURIComponent(value)}`);
          }
          return result;
        },
        [] as string[]
      ).join('&');
    };

    let urlQuery = makeQuery({
      $query: query,
      ...sharedQueryParams
    });

    if (!clientContextVariables.length && clientContextURIEncoded?.length) {
      urlQuery = urlQuery + clientContextURIEncoded;
    }

    const fullURL = `${resourceUri}?${urlQuery}`;

    if (query.length > SODA_2_MIN_QUERY_POST_SIZE && fullURL.length > MAX_URL_SIZE) {
      const queryParams = makeQuery(sharedQueryParams);
      return fetchJsonWithDefaultHeaders(`/api/query/${queryUid}.${queryFormat}?${queryParams}`, {
        method: 'POST',
        body: JSON.stringify({ query })
      });
    } else {
      return fetchJsonWithDefaultHeaders(fullURL);
    }
  } else {
    throw new Error('Given resourceUri is not a resource uri.');
  }
};

class SoqlError extends Error {
  status: string;
  message: string;
  soqlError?: Record<string, unknown> | string;
}

/**
 * Fetch a JSON response from the v3 Socrata API according to the prepared SoQL query.
 *
 * @param resourceUri A string in the shape of 'https://some.domain/resource/four-four.json'.
 * @param preparedStatement A function that returns the result of composeQuery or chainQueries.
 * @param statementParameters An arbitrary array of arguments to pass into the preparedStatement.
 * @param options Additional options to control how the query is executed
 */
export const invoke = async <T>(
  resourceUri: ResourceURI,
  preparedStatement: ParameterizedSoqlQuery,
  statementParameters: any[],
  options: {
    /** Client context variables with override values to be passed along */
    clientContextVariables?: ClientContextVariable[];
    queryTimeout?: number;
  } = {}
): Promise<T> => {
  // common/viz relies on soql_builder, so we include `common_viz_only` as valid soda3 here.
  const soda3 = ['all', 'common_viz_only'].includes(FeatureFlags.valueOrDefault('soda3_viz', 'none'));
  if (!soda3) {
    return invokeSoda2(resourceUri, preparedStatement, statementParameters, options);
  }

  // TODO: When removing soda3_viz, we can just update this method to directly take
  // the queryUid and queryFormat
  const regexMatches = resourceUri.match(uriRegex);
  if (!isResourceURI(resourceUri) || !regexMatches) {
    throw new Error(`Given resourceUri (${resourceUri}) is not a resource uri.`);
  }

  const { clientContextVariables = [], queryTimeout } = options;
  const queryUid = regexMatches[1];
  const queryFormat = regexMatches[2];
  const queryUri = `/api/v3/views/${queryUid}/query.${queryFormat}`;
  const query = preparedStatement(...statementParameters);

  const postBody: SoQLV3APIOptions = {
    timeout: queryTimeout || undefined,
    parameters: clientContextVariables.length
      ? { qualified: toV3OverrideParams(clientContextVariables) }
      : undefined
  };

  const getURL = `${queryUri}?query=${encodeURIComponent(query)}`;

  try {
    if (compact(values(postBody)).length || getURL.length > MAX_URL_SIZE) {
      return await fetchJsonWithFederation(queryUri, {
        method: 'POST',
        body: JSON.stringify({ query, ...postBody })
      });
    } else {
      return await fetchJsonWithFederation(getURL);
    }
  } catch ({ response }) {
    const error = new SoqlError(response.statusText);
    error.status = response.status;
    error.message = response.statusText;
    error.soqlError = (await response.json()) ?? (await response.text());
    throw error;
  }
};

export {
  buildSelect as select,
  buildSystemStar as systemStar,
  buildUserStar as userStar,
  buildPrerenderedExpression as prerenderedSelect,
  buildDistinctOn as distinctOn,
  buildInnerJoin as innerJoin,
  buildOuterJoin as outerJoin,
  buildLeftJoin as leftJoin,
  buildRightJoin as rightJoin,
  buildFullJoin as fullJoin,
  buildTableName as tableName,
  buildSubSelect as subSelect,
  decorateJoinWithLateral as lateral,
  buildOrderBy as orderBy,
  buildWindowFunction as windowFunc,
  wfOver as over,
  functions as f,
  buildWhereAnd as and,
  buildWhereOr as or,
  compounds as c,
  buildUnion as union,
  buildUnionAll as unionAll,
  buildIntersect as intersect,
  buildMinus as minus,
  buildChain as chain,
  buildChain as pipe,
  buildLiteral as literal,
  encodeStringAsColumnRef as ref,
  buildQualifiedViewColumn as qualify
};
