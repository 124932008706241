import {
  ExportFormat,
  getDownloadLink,
  getExportFormats,
  IGNORED_FORMATS_FOR_DATE,
  supportsGeoExport
} from 'common/downloadLinks';
import { lastInChain } from 'common/explore_grid/lib/selectors';
import { FeatureFlags } from 'common/feature_flags';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { QueryCompilationSucceeded, isCompilationSucceeded } from 'common/types/compiler';
import {
  SoQLType,
  TypedExpr,
  TypedSoQLColumnRef,
  isLeaf,
  isTypedColumnRef,
  isTypedFunCall,
  soqlRendering
} from 'common/types/soql';
import { ResourceState, ResourceType } from './ExportModalTypes';
import { Option } from 'ts-option';
import { View } from 'common/types/view';
import { fetchTranslation } from 'common/locale';
import { toTypedOverrideParamString } from 'common/core/client_context_variables';
import { chain, Dictionary, isUndefined, omitBy } from 'lodash';
import moment from 'moment';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.export_dataset_modal');

export const getResourceState = (
  view: View,
  query: Option<QueryCompilationSucceeded>,
  clientContextVariables: ClientContextVariable[],
  queryString: Option<string>,
  isTableViz: boolean,
  fourfour?: string,
  revisionSeq?: number,
  currentResourceState?: ResourceState,
  useResourceName?: boolean,
  odataSelectClause?: string
) => {
  // if there's a query, do this...
  const formats = query
    .map((qs) => {
      const selectionTypes = lastInChain(qs.analyzed).selection.reduce((acc: SoQLType[], selectedExpr) => {
        if (selectedExpr.expr.soql_type !== null) {
          return [selectedExpr.expr.soql_type, ...acc];
        }
        return acc;
      }, []);

      // :@ is invalid in XML. If the select includes a computed column, core will
      // mark it as hidden on xml export, causing query-coordinator to throw a
      // column-not-found error if we try to select it. This happens even if the
      // computed column is aliased.. So if a computed column is in the select,
      // do not show XML export as an option.
      function invalidSelectForXML(q: QueryCompilationSucceeded): boolean {
        // don't care about other compilation states since export is disabled for them
        if (isCompilationSucceeded<QueryCompilationSucceeded>(q)) {
          if (isLeaf(q.analyzed)) {
            // have to recursively crawl ast because user could pass select computed column
            // to a function
            return hasComputedColumn(q.analyzed?.value?.selection.map((select) => select.expr));
          }
        }
        return false;
      }

      return getExportFormats(view, isTableViz, invalidSelectForXML(qs), selectionTypes);
    })
    .getOrElseValue(getExportFormats(view, isTableViz));

  let maybeQueryString;
  // We either use the queryString or we use the query.
  if (queryString.isDefined) {
    // We are using the select and where clauses
    maybeQueryString = queryString;
  } else {
    // We are using the query
    maybeQueryString = query.map((qs) => soqlRendering.unwrap(qs.rendering));
  }

  const downloadResources = makeResourcesDownloads(
    view,
    maybeQueryString,
    formats,
    clientContextVariables,
    fourfour,
    revisionSeq
  );

  const apiResources = makeResourcesApi(view, maybeQueryString, clientContextVariables, useResourceName);

  const OdataResources = makeResourcesOdata(view, odataSelectClause);

  const getSelectedDownloadResource = () => {
    if (currentResourceState) {
      // see if the selected resource is in our list, otherwise fall through and use the default resource
      const foundResource = downloadResources.find(
        (v) => v.value === currentResourceState.selectedDownloadResource?.value
      );
      if (foundResource) return foundResource;
    }
    return downloadResources.find((v) => v.defaultType);
  };

  const getSelectedApiResource = () => {
    if (currentResourceState) {
      const foundResource = apiResources.find(
        (v) => v.value === currentResourceState.selectedApiResource.value
      );
      if (foundResource) return foundResource;
    }
    return apiResources.find((v) => v.defaultType);
  };

  const getSelectedOdataResource = () => {
    if (currentResourceState) {
      const foundResource = OdataResources.find(
        (v) => v.value === currentResourceState.selectedOdataResource.value
      );
      if (foundResource) return foundResource;
    }
    return OdataResources.find((v) => v.defaultType);
  };

  const resourceState: ResourceState = {
    selectedDownloadResource: getSelectedDownloadResource(),
    apiResources: apiResources,
    odataResources: OdataResources,
    downloadResources: downloadResources,
    // there will always be a defaultType because we hard coded it
    // @ts-expect-error TS(2322) FIXME: Type 'ResourceType | undefined' is not assignable ... Remove this comment to see the full error message
    selectedApiResource: getSelectedApiResource(),
    // there will always be a defaultType because we hard coded it
    // @ts-expect-error TS(2322) FIXME: Type 'ResourceType | undefined' is not assignable ... Remove this comment to see the full error message
    selectedOdataResource: getSelectedOdataResource()
  };

  return resourceState;
};

const protocolAndHost = () => `${window.location.protocol}//${window.location.host}`;

const hasComputedColumn = (exprs: TypedExpr[]): boolean => {
  return exprs.some((expr) => hasComputedColumnHelper(expr));
};

const hasComputedColumnHelper = (expr: TypedExpr): boolean => {
  if (isTypedFunCall(expr)) {
    return hasComputedColumn(expr.args);
  } else if (isTypedColumnRef(expr)) {
    // tsc doesn't realize we just have a TypedSoQLColumnRef here
    return (expr as TypedSoQLColumnRef).value.startsWith(':@');
  } else {
    return false;
  }
};

const makeResourcesApi = (
  view: View,
  query: Option<string>,
  clientContextVariables: ClientContextVariable[],
  useResourceName?: boolean
): ResourceType[] => {
  // I believe we would want to set this based if the user is using a resource_name
  // We optionally use resource name or four-four
  const identifier = useResourceName && view?.resourceName ? view.resourceName : view.id;

  const resourceLinks = [
    {
      label: t('csv'),
      value: 'csv' as ExportFormat,
      url: queryUrl(identifier, query, 'csv', clientContextVariables),
      defaultType: false
    },
    {
      label: t('json'),
      value: 'json' as ExportFormat,
      url: queryUrl(identifier, query, 'json', clientContextVariables),
      defaultType: true
    }
  ];

  if (supportsGeoExport(view)) {
    resourceLinks.push({
      label: t('geojson'),
      value: 'geojson' as ExportFormat,
      url: queryUrl(identifier, query, 'geojson', clientContextVariables),
      defaultType: false
    });
  }
  return resourceLinks;
};

const downloadOptions = (
  view: View,
  format: ExportFormat,
  queryString: Option<string>,
  fourfour?: string,
  revisionSeq?: number
) => {
  let queryParams: Dictionary<any> = omitBy(
    {
      query: queryString.nonEmpty ? queryString.get : undefined,
      fourfour: fourfour,
      revisionSeq: revisionSeq
    },
    isUndefined
  );

  if (queryString.nonEmpty) {
    queryParams = {
      ...queryParams,
      read_from_nbe: true,
      version: 2.1
    };
  }

  // this parameter ensures the browser doesn't use a cached version after an update
  if (view) {
    queryParams.cacheBust = chain([view.rowsUpdatedAt, view.createdAt, view.viewLastModified])
      .compact()
      .max()
      .value();
  }

  if (IGNORED_FORMATS_FOR_DATE.indexOf(format) === -1) {
    queryParams.date = moment().format('YYYYMMDD');
  }

  return {
    queryParams,
    cname: window.location.hostname,
    format
  };
};

const makeResourcesDownloads = (
  view: View,
  queryString: Option<string>,
  exportFormats: ExportFormat[],
  clientContextVariables: ClientContextVariable[],
  fourfour?: string,
  revisionSeq?: number
): ResourceType[] => {
  return exportFormats.map((format) => {
    const url =
      getDownloadLink(view.id, downloadOptions(view, format, queryString, fourfour, revisionSeq)) || '';
    return {
      label: t(format),
      value: format,
      url: clientContextVariables.length
        ? `${url}&${toTypedOverrideParamString(clientContextVariables)}`
        : url,
      defaultType: format === 'csv'
    };
  });
};

const makeResourcesOdata = (view: View, odataSelectClause = ''): ResourceType[] => {
  const url = (V2orV4ApiPath: string) =>
    `${protocolAndHost()}/${V2orV4ApiPath}/${view.id}${odataSelectClause}`;
  const resourceLinks = [
    {
      label: 'OData V4',
      value: 'odataUrlV4' as ExportFormat,
      url: url('api/odata/v4'),
      defaultType: true
    },
    {
      label: 'OData V2',
      value: 'odataUrlV2' as ExportFormat,
      url: url('Odata.svc'),
      defaultType: false
    }
  ];

  return resourceLinks;
};

const queryUrl = (
  ff: string,
  query: Option<string>,
  type: 'csv' | 'json' | 'geojson',
  clientContextVariables: ClientContextVariable[]
) => {
  const useV3SodaApiEndpoints = FeatureFlags.value('soda3_exports');
  const shouldIncludeClientContextVariables = clientContextVariables.length && !useV3SodaApiEndpoints;
  let url = useV3SodaApiEndpoints
    ? `${protocolAndHost()}/api/v3/views/${ff}/query.${type}` // v3
    : `${protocolAndHost()}/resource/${ff}.${type}`; // v2
  if (query.nonEmpty || shouldIncludeClientContextVariables) {
    url = `${url}?`;
  }
  if (query.nonEmpty) {
    url = `${url}${!useV3SodaApiEndpoints ? '$' : ''}query=${encodeURIComponent(query.get)}`;
  }
  if (shouldIncludeClientContextVariables) {
    url = `${url}&${toTypedOverrideParamString(clientContextVariables)}`;
  }
  return url;
};
