import _ from 'lodash';
import $ from 'jquery';
import DataProvider, { DataProviderConfig } from './DataProvider';
import { Option, none } from 'ts-option';

import {
  assert,
  assertHasProperties,
  assertHasProperty,
  assertInstanceOf,
  assertIsOneOfTypes
} from 'common/assertions';
import formatString from 'common/js_utils/formatString';

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './So... Remove this comment to see the full error message
import { mapSoqlRowsResponseToTable, filterToWhereClauseComponent } from './SoqlHelpers';
import { appToken, csrfToken } from 'common/http';
import FeatureFlags from 'common/feature_flags';
import { FILTER_SORTING, FILTER_SORTING_TYPES } from 'common/authoring_workflow/constants';
import { SERIES_TYPE_TABLE } from '../views/SvgConstants';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import { ViewColumn } from 'common/types/viewColumn';
import {
  select as soqlSelect,
  f,
  orderBy as soqlOrderBy,
  composeQuery,
  invoke,
  ParameterizedSoqlQuery,
  ref
} from 'common/soql_builder';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { toTypedOverrideParamString } from 'common/core/client_context_variables';

export interface SoqlDataProviderConfig extends DataProviderConfig {
  domain?: string;
  /** @default true */
  readFromNbe?: boolean;
  /** All queries will be made against this datasetUid. Some methods provide an option to override this. */
  datasetUid: string;
  queryTimeout?: number;
  /**
   * Client context variables to apply to all queries made by this instance of SoqlDataProvider.
   * Inherited variables should have `viewId` set to the view that the client context variables were created on.
   */
  clientContextVariables?: ClientContextVariable[];
}

/**
 * `SoqlDataProvider` is an implementation of `DataProvider` that enables
 * users to query SoQL data sources on the current domain.
 *
 * @constructor
 *
 * @param {Object} config
 * @property {String} domain - The domain against which to make the query.
 * @property {String} datasetUid - The uid of the dataset against which
 *  the user intends to query.
 * @property {Boolean} readFromNbe - [Optional] sets $$read_from_nbe=true if true
 * @property {String} queryTimeout - [Optional] sets $$query_timeout_seconds=<value> if provided
 *                                              (so that some queries can be tracked from logs)
 */
class SoqlDataProvider extends DataProvider {
  soqlRequestPromiseCache: { [key: string]: Promise<any> };

  static MAX_GET_REQUEST_SIZE = 8192;

  // TODO EN-68740: enable useCache by default
  constructor(config: SoqlDataProviderConfig, useCache = false) {
    super({
      ...config,
      domain: config.domain || getDefaultDomain()
    } as SoqlDataProviderConfig);

    this.soqlRequestPromiseCache = {};

    assertHasProperty(this.config, 'domain');
    assertHasProperty(this.config, 'datasetUid');

    assertIsOneOfTypes(this.config.domain, 'string');
    assertIsOneOfTypes(this.config.datasetUid, 'string');

    if (useCache) {
      const cached = this.cachedInstance('SoqlDataProvider');
      if (cached) {
        return cached as SoqlDataProvider;
      }
    }
  }

  /**
   * `.query()` executes a SoQL query against the current domain that returns
   * key => value pairs. The query string is passed in by the caller, meaning
   * that at this level of abstraction we have no notion of SoQL grammar.
   *
   * A note on `nameAlias` and `valueAlias`:
   *
   *   Since it is possible that columns have names that may collide with
   *   SoQL keywords (e.g. a column named 'null'), we alias all fields in
   *   the SELECT clause like this:
   *
   *     "SELECT `null` as ALIAS_NAME, `false` AS ALIAS_VALUE..."
   *
   *   These aliases are set by the caller and will also be used as column
   *   names in the resulting 'table' object returned by the request.
   *
   * @param {String} queryString - A valid, non-URI-encoded SoQL query.
   * @param {String} nameAlias - The alias used for the 'name' column.
   * @param {String} valueAlias - The alias used for the 'value' column.
   * @param {String} errorBarsLowerAlias - The alias used for the error bars lower bound column. Can be undefined.
   * @param {String} errorBarsUpperAlias - The alias used for the error bars upper bound column. Can be undefined.
   * @param {String} groupingAlias - The alias used for grouping columns. Can be undefined.
   *
   * @return {Promise}
   */
  async query(
    queryString: string,
    nameAlias: string,
    valueAlias: string,
    errorBarsLowerAlias?: string,
    errorBarsUpperAlias?: string,
    groupingAlias?: string
  ) {
    let data;

    if (this.useSoda3Analyzer()) {
      data = await this.invokeSoqlQuery(() => queryString, []);
    } else {
      data =
        queryString.length > SoqlDataProvider.MAX_GET_REQUEST_SIZE
          ? await this.makeSoqlPostRequest({ query: queryString })
          : await this.makeSoqlGetRequest(this.pathForQuery(`$query=${encodeURIComponent(queryString)}`));
    }

    const basicAliases = !_.isUndefined(groupingAlias)
      ? [nameAlias, groupingAlias, valueAlias]
      : [nameAlias, valueAlias];

    let errorBarsAliases;

    if (!_.isEmpty(errorBarsLowerAlias) && !_.isEmpty(errorBarsUpperAlias)) {
      errorBarsAliases = [nameAlias, errorBarsLowerAlias, errorBarsUpperAlias];
    }

    return mapSoqlRowsResponseToTable(basicAliases, data, SERIES_TYPE_TABLE, errorBarsAliases);
  }

  /**
   * uses common/soql_builder.invoke to call the API, and caches the result
   */
  invokeSoqlQuery(
    preparedStatement: ParameterizedSoqlQuery,
    queryParameters: any[],
    viewId?: string,
    format: 'geojson' | 'json' = 'json'
  ) {
    const resourceUri = this.resourceUri(format, viewId);
    const query = preparedStatement(...queryParameters);
    const cacheKey = this.cacheKey(query, preparedStatement.name);

    if (this.soqlRequestPromiseCache.hasOwnProperty(cacheKey)) {
      return this.soqlRequestPromiseCache[cacheKey];
    }

    const options = _.pick(this.optionsForQuery(), ['soqlVersion', 'clientContextVariables', 'queryTimeout']);

    const requestPromise = invoke(resourceUri, preparedStatement, queryParameters, options);

    this.soqlRequestPromiseCache[cacheKey] = requestPromise;

    return requestPromise;
  }

  searchInColumn({
    columnName,
    filters,
    isLazyLoading,
    limit = 10,
    offset = 0,
    searchTerm,
    orderBy = 'best',
    startingQuery = none
  }: {
    columnName: string;
    filters: SoqlFilter[];
    isLazyLoading?: boolean;
    limit?: number;
    offset?: number;
    searchTerm: string;
    orderBy?: string;
    startingQuery?: Option<string>;
  }): Promise<Array<any>> {
    if (!_.isString(searchTerm)) {
      return Promise.resolve([]);
    }

    const filterConditions = this.getFilterWhereConditions(filters, columnName);
    const suggestionAlias = '__suggestion';
    const scoreAlias = '__score';
    let baseQuery = 'SELECT DISTINCT `{0}` WHERE UPPER(`{0}`::text) like "%{1}%" ';

    if (!_.isEmpty(filterConditions)) {
      baseQuery += `AND ${filterConditions} `;
    }

    let ordering = `ORDER BY ${scoreAlias} desc`;
    if (orderBy === 'alphabetical') {
      ordering = `ORDER BY ${suggestionAlias} asc`;
    }

    baseQuery +=
      `|> SELECT \`{0}\`::text as ${suggestionAlias}, ` +
      `CASE(UPPER(${suggestionAlias}) like "{1}%", 1, true, 0) as ${scoreAlias} ` +
      `${ordering} ` +
      'LIMIT {2}';

    if (isLazyLoading) {
      baseQuery += ` OFFSET ${offset}`;
    }

    // In some cases the data in the dataset might have slightly bad data like
    // Police  department  <notice double space>
    // or data having special characters instead of spaces
    // police.department
    //
    // For a search term like 'police department' (even better 'pol department') to work
    // with the above data, we replace special-characters/spaces with wildcards before we search.
    const optimizedSearchTerm = searchTerm.replace(/[^a-z^A-Z^0-9]/g, '%');

    startingQuery.forEach((query) => {
      baseQuery = query + ' |> ' + baseQuery;
    });

    const query = formatString(baseQuery, columnName, optimizedSearchTerm.toUpperCase(), limit);
    return this.rawQuery(query).then((results: any) => {
      return _.map(results, (result) => result[suggestionAlias]);
    });
  }

  getDistinctValuesInColumn(
    datasetUid: string,
    column: string,
    alias: string,
    limit?: number,
    offset?: number
  ) {
    const baseQuery = `SELECT distinct(${column}) as ${alias} ORDER BY ${column}`;
    const limitQuery = limit ? ` LIMIT ${limit}` : '';
    const offsetQuery = offset ? ` OFFSET ${offset}` : '';
    const query = baseQuery + offsetQuery + limitQuery;
    return this.rawQuery(query, datasetUid).then((results: any) => results);
  }

  getTopXOptionsInColumn(
    {
      allFilters,
      column,
      filter,
      limit,
      offset
    }: {
      allFilters: SoqlFilter[];
      column: ViewColumn;
      filter: SoqlFilter;
      limit: number;
      offset: number;
    },
    datasetUid?: string
  ) {
    const defaultFilterOrderBy = FILTER_SORTING[0].orderBy;
    const filterOrderBy = filter.orderBy;
    const orderBy = !filterOrderBy || _.isEmpty(filterOrderBy) ? defaultFilterOrderBy : filterOrderBy;
    const columnName = _.get(column, 'fieldName');
    const orderByParameter = _.get(orderBy, 'parameter');
    const filterConditions = this.getFilterWhereConditions(allFilters, columnName);
    let query;
    const queryOptions = {
      columnName,
      filterConditions,
      limit,
      offset,
      sort: orderBy.sort
    };

    if (orderByParameter === FILTER_SORTING_TYPES.alphabetical.title) {
      query = this.getSortByLabelQuery(queryOptions);
    } else {
      query = this.getSortByValueQuery(queryOptions);
    }

    return this.rawQuery(query, datasetUid).then((results: any) => results);
  }

  // NOTE: This is not private to allow tests to access it
  getSortByLabelQuery({
    columnName,
    filterConditions,
    limit,
    offset,
    sort
  }: {
    columnName: string;
    filterConditions: string | null;
    limit: number | null;
    offset: number | null;
    sort: string;
  }): string {
    let baseQuery = 'SELECT `{0}` ';

    if (!_.isEmpty(filterConditions)) {
      baseQuery += `WHERE ${filterConditions} `;
    }
    baseQuery += 'GROUP BY `{0}` ORDER BY `{0}` {1}';

    if (!_.isNull(limit)) {
      baseQuery += ' LIMIT {2}';
    }

    if (!_.isNull(offset)) {
      baseQuery += ' OFFSET {3}';
    }

    return formatString(baseQuery, columnName, sort, limit, offset);
  }

  // NOTE: This is not private to allow tests to access it
  getSortByValueQuery({
    columnName,
    filterConditions,
    limit,
    offset,
    sort
  }: {
    columnName: string;
    filterConditions: string | null;
    limit: number | null;
    offset: number | null;
    sort: string;
  }) {
    const countAlias = '__count_alias__';
    let baseQuery = 'SELECT `{0}`, count(`{0}`) as `{1}` ';

    if (!_.isEmpty(filterConditions)) {
      baseQuery += `WHERE ${filterConditions} `;
    }

    baseQuery += 'GROUP BY `{0}` ORDER BY `{1}` {2}';

    if (!_.isNull(limit)) {
      baseQuery += ' LIMIT {3}';
    }

    if (!_.isNull(offset)) {
      baseQuery += ' OFFSET {4}';
    }

    return formatString(baseQuery, columnName, countAlias, sort, limit, offset);
  }

  getSpatialLensRegions(primaryKey: string | undefined, regionIds: string[]) {
    if (!primaryKey) {
      throw new Error('Must provide primary_key to get spatial lenses.');
    }

    let query = 'SELECT *';

    if (regionIds.length > 0) {
      query += ` WHERE ${primaryKey} IN (${regionIds.join(', ')})`;
    }

    return this.rawQuery(query);
  }

  searchInSpatialLensDataset({
    associatedDataColumnName,
    filters,
    searchColumnName,
    searchTerm,
    limit = 10
  }: {
    associatedDataColumnName: string;
    filters: SoqlFilter[];
    searchColumnName: string;
    searchTerm: string;
    limit: number;
  }) {
    if (!_.isString(searchTerm)) {
      return Promise.resolve([]);
    }

    // In some cases the data in the dataset might have slightly bad data like
    // Police  department  <notice double space>
    // or data having special characters instead of spaces
    // police.department
    //
    // For a search term like 'police department' (even better 'pol department') to work
    // with the above data, we replace special-characters/spaces with wildcards before we search.
    const optimizedSearchTerm = searchTerm.replace(/[^a-z^A-Z^0-9]/g, '%');

    const filterConditions = this.getFilterWhereConditions(filters, searchColumnName);
    const suggestionAlias = '__suggestion';
    const associatedDataAlias = '__associated_data';
    const scoreAlias = '__score';
    let baseQuery = `SELECT \`${searchColumnName}\`, \`${associatedDataColumnName}\` WHERE UPPER(\`${searchColumnName}\`::text) like "%${optimizedSearchTerm.toUpperCase()}%" `;

    if (!_.isEmpty(filterConditions)) {
      baseQuery += `AND ${filterConditions} `;
    }

    baseQuery +=
      `GROUP BY \`${searchColumnName}\`, \`${associatedDataColumnName}\` |> ` +
      `SELECT \`${searchColumnName}\` as ${suggestionAlias}, \`${associatedDataColumnName}\` as ${associatedDataAlias} , CASE(UPPER(${suggestionAlias}::text) like "${optimizedSearchTerm.toUpperCase()}%", 1, true, 0) as ${scoreAlias} ` +
      `ORDER BY ${scoreAlias} desc ` +
      `LIMIT ${limit}`;

    return this.rawQuery(baseQuery).then((results) => {
      return _.map(results, (result) => _.pick(result, [suggestionAlias, associatedDataAlias]));
    });
  }

  /**
   * @deprecated EN-68741: use invokeSoqlQuery instead
   * `.rawQuery()` is basically `.query()` without any of the nonsense that ties it to visualizations.
   * It allows you to execute SoQL without worrying about path or request configurations
   *
   * @param {String} queryString - A valid, non-URI-encoded SoQL query.
   *
   * @return {Promise}
   */
  rawQuery(queryString: string, datasetUid?: string) {
    if (!this.useSoda3Analyzer()) {
      const path = this.pathForQuery(`$query=${encodeURIComponent(queryString)}`, datasetUid);

      return this.makeSoqlGetRequest(path);
    }

    return this.invokeSoqlQuery(() => queryString, [], datasetUid);
  }

  getRowCount(whereClauseComponents?: string) {
    const alias = '__count_alias__'; // lowercase in order to deal with OBE norms

    if (!this.useSoda3Analyzer()) {
      const whereClause = whereClauseComponents ? `&$where=${encodeURIComponent(whereClauseComponents)}` : '';
      const path = this.pathForQuery(`$select=count(*) as ${alias}${whereClause}`);

      return this.makeSoqlGetRequest(path).then(this.parseRowCountQueryResults(alias));
    }

    let queryString = `SELECT COUNT(*) AS ${alias}`;
    if (!_.isEmpty(whereClauseComponents)) {
      queryString += ` WHERE ${whereClauseComponents}`;
    }
    return this.invokeSoqlQuery(() => queryString, []).then(this.parseRowCountQueryResults(alias));
  }

  getRowCountForQuery(query: string, viewId?: string) {
    const alias = '__count_alias__';
    if (!this.useSoda3Analyzer()) {
      const path = this.pathForQuery(
        `$query=${encodeURIComponent(query)} |> select count(*) as ${alias}`,
        viewId
      );
      return this.makeSoqlGetRequest(path).then(this.parseRowCountQueryResults(alias));
    }

    const queryString = `${query} |> select count(*) as ${alias}`;
    return this.invokeSoqlQuery(() => queryString, [], viewId).then(this.parseRowCountQueryResults(alias));
  }

  /**
   * `.getRows()` executes a SoQL query against the current domain that
   * returns all rows. The response is mapped to the DataProvider data schema (1).
   * The query string is passed in by the caller, meaning
   * that at this level of abstraction we have no notion of SoQL grammar.
   *
   * @param {String[]} columnNames - A list of column names to extract from the response.
   * @param {String} queryString - A valid SoQL query.
   *
   * (1) - The DataProvider data schema:
   * {
   *   columns: {String[]},
   *   rows: {{Object[]}[]}.
   * }
   * Row:
   *
   * Example:
   * {
   *   columns: [ 'date', 'id' ],
   *   rows: [
   *    [ '2016-01-15T11:08:45.000', '123' ],
   *    [ '2016-01-15T11:08:45.000', '345' ]
   *   ]
   * }
   *
   * @return {Promise}
   */
  getRows(columnNames: string[], queryString: string) {
    assertInstanceOf(columnNames, Array);
    assert(columnNames.length > 0, 'columnNames must be a non-empty array');
    assertIsOneOfTypes(queryString, 'string');
    _.each(columnNames, (columnName) => {
      assertIsOneOfTypes(columnName, 'string');
    });

    if (!this.useSoda3Analyzer()) {
      const path = this.pathForQuery(queryString);
      return this.makeSoqlGetRequest(path).then((soqlData) => {
        return mapSoqlRowsResponseToTable(columnNames, soqlData);
      });
    }

    return this.invokeSoqlQuery(() => queryString, []).then((soqlData) => {
      return mapSoqlRowsResponseToTable(columnNames, soqlData);
    });
  }

  /**
   * `.getTableData()`
   *
   * @deprecated See other helpers in TableDataHelpers or call invokeSoqlQuery directly.
   *
   * Gets a page of data from the dataset. In addition to an offset
   * and limit, you must specify an ordering and a list of columns.
   *
   * @param {String[]} columnNames - Columns to grab data from.
   * @param {Object[]} order - An array of order clauses. A clause looks like:
   *                           {
   *                             columnName: {String} - a column,
   *                             ascending: {Boolean} - ascending or descending
   *                           }
   * @param {Number} offset - Skip this many rows.
   * @param {Number} limit - Fetch this many rows, starting from offset.
   * @param {String} whereClauseComponents - Conditions which rows should match.
   * @param {String} tableType - Determines how we retrieve row data for `table` or `agTable`
   *
   * @return {Promise}
   */
  getTableData(
    columnNames: string[],
    order: { columnName: string; ascending: boolean }[],
    offset: number,
    limit: number,
    whereClauseComponents?: string,
    tableType?: string
  ) {
    assertInstanceOf(columnNames, Array);
    assertIsOneOfTypes(offset, 'number');
    assertIsOneOfTypes(limit, 'number');

    if (!this.useSoda3Analyzer()) {
      let orderSoQL;
      if (_.isEmpty(order)) {
        orderSoQL = '';
      } else {
        assertHasProperties(order, '[0].ascending', '[0].columnName');
        // NOTE: We will only have row ids if we are querying an NBE dataset. We cannot,
        // at least at this time, construct a SoQL query that works against the OBE and
        // includes both all the user rows and only the :id system column.
        const orders = order.map((o) => {
          const direction = o.ascending ? 'ASC' : 'DESC';
          return `\`${o.columnName}\`+${direction}`;
        });
        orderSoQL = `&$order=${orders.join(',')}`;
      }

      const whereClause = whereClauseComponents ? '&$where=' + encodeURIComponent(whereClauseComponents) : '';
      const queryString = `$select=*${orderSoQL}&$limit=${limit}&$offset=${offset}${whereClause}`;
      const path = this.pathForQuery(queryString);

      return this.makeSoqlGetRequest(path).then((data) => {
        return mapSoqlRowsResponseToTable(columnNames, data, tableType);
      });
    }

    // includes both all the user rows and only the :id system column.
    const orders = order.map((o) => {
      const direction = o.ascending ? 'ASC' : 'DESC';
      return `\`${o.columnName}\` ${direction}`;
    });
    const orderSoQL = `ORDER BY ${orders.join(', ')}`;

    const whereClause = whereClauseComponents ? 'WHERE ' + whereClauseComponents : '';
    const queryString = `SELECT * ${whereClause} ${orderSoQL} LIMIT ${limit} OFFSET ${offset}`;

    return this.invokeSoqlQuery(() => queryString, []).then((data) => {
      return mapSoqlRowsResponseToTable(columnNames, data, tableType);
    });
  }

  // Requests aggregate statistics about the data in all of the columns.  This potentially fires
  // off many data requests that perform slow queries, use with caution.
  getColumnStats(columns: ViewColumn[], datasetUid?: string): Promise<any> {
    assert(_.isArray(columns), 'columns parameter must be an array');
    const promises: Promise<any>[] = _.map(columns, (column) => {
      const { renderTypeName, flags } = column;
      // For number and calendar_date columns, we need the min and max of the column
      // Hidden columns should be ignored.
      if (_.includes(flags, 'hidden')) {
        return Promise.resolve(null);
      } else if (_.includes(['money', 'number', 'calendar_date', 'date'], renderTypeName)) {
        return Promise.resolve(this.getNumberColumnStats(column, datasetUid));
      } else if (renderTypeName === 'text') {
        return Promise.resolve(this.getTextColumnStats(column, datasetUid));
      } else {
        return Promise.resolve(null);
      }
    });

    return Promise.all(promises);
  }

  match(columnName: string, term: string): Promise<void> {
    let soqlPromise: Promise<any>;

    if (this.useSoda3Analyzer()) {
      const matchQuery = (fieldName: string, termToMatch: string) =>
        composeQuery({
          selects: [soqlSelect(ref(fieldName), fieldName)],
          where: f.eq(ref(fieldName), termToMatch),
          limit: 1
        });

      soqlPromise = this.invokeSoqlQuery(matchQuery, [columnName, term]);
    } else {
      const escapedColumnName = this.escapeColumnName(columnName);
      const select = `${escapedColumnName}`;
      const where = encodeURIComponent(`${escapedColumnName}="${term}"`);
      const queryString = `$select=${select}&$where=${where}&$limit=1`;
      const path = this.pathForQuery(queryString);

      soqlPromise = this.makeSoqlGetRequest(path);
    }

    return soqlPromise.then((result) => {
      return new Promise((resolve, reject) => {
        return _.isArray(result) && result.length === 1 ? resolve() : reject();
      });
    });
  }

  getTextColumnStats(column: ViewColumn, datasetUid?: string) {
    const { fieldName, cachedContents } = column;

    if (_.has(cachedContents, 'top')) {
      return {
        top: _.get(cachedContents, 'top')
      };
    } else {
      const countAlias = '__count__';

      let soqlPromise: Promise<any>;

      if (this.useSoda3Analyzer()) {
        const textColumnStatsQuery = (forColumn: ViewColumn) =>
          composeQuery({
            selects: [soqlSelect(forColumn, 'item'), soqlSelect(f.count('*'), countAlias)],
            where: f.isNotNull(forColumn),
            orders: [soqlOrderBy(countAlias, 'descending')],
            groups: [forColumn],
            limit: 25
          });

        soqlPromise = this.invokeSoqlQuery(textColumnStatsQuery, [column], datasetUid);
      } else {
        const escapedFieldName = this.escapeColumnName(fieldName);

        const select = `${escapedFieldName}+as+item,count(*)+as+${countAlias}`;
        const where = `${escapedFieldName}+is+not+null`;
        const orderBy = `${countAlias}+DESC`;
        const queryString = `$select=${select}&$where=${where}&$order=${orderBy}&$group=${escapedFieldName}&$limit=25`;
        const path = this.pathForQuery(queryString, datasetUid);

        soqlPromise = this.makeSoqlGetRequest(path);
      }

      return soqlPromise.then((result) => {
        return {
          top: result
        };
      });
    }
  }

  getNumberColumnStats(column: ViewColumn, datasetUid?: string) {
    const { fieldName, renderTypeName, cachedContents } = column;

    if (_.has(cachedContents, 'smallest') && _.has(cachedContents, 'largest')) {
      return this.buildNumberOrDateRange(
        renderTypeName,
        _.get(cachedContents, 'smallest'),
        _.get(cachedContents, 'largest')
      );
    } else {
      const minAlias = '__min__';
      const maxAlias = '__max__';

      let soqlPromise: Promise<any>;
      if (this.useSoda3Analyzer()) {
        const numberColumnStatsQuery = (forColumn: ViewColumn) =>
          composeQuery({
            selects: [soqlSelect(f.min(forColumn), minAlias), soqlSelect(f.max(forColumn), maxAlias)]
          });

        soqlPromise = this.invokeSoqlQuery(numberColumnStatsQuery, [column], datasetUid);
      } else {
        const select = `min(${this.escapeColumnName(fieldName)}) as ${minAlias}, max(${this.escapeColumnName(
          fieldName
        )}) as ${maxAlias}`;
        const queryString = `$select=${select}`;
        const path = this.pathForQuery(queryString, datasetUid);

        soqlPromise = this.makeSoqlGetRequest(path);
      }

      return soqlPromise.then((result) => {
        return Promise.resolve(
          this.buildNumberOrDateRange(renderTypeName, result?.[0]?.[minAlias], result?.[0]?.[maxAlias])
        );
      });
    }
  }

  private parseRowCountQueryResults(alias: string) {
    return (data: any) => parseInt(_.get(data, `[0]${alias}`), 10);
  }

  // Returns a Promise for a GET against the given SoQL url.
  // On error, rejects with an object: {
  //   status: HTTP code,
  //   message: status text,
  //   soqlError: response JSON
  // }
  // TODO: Remove with soda3_viz
  private async makeSoqlGetRequest(path: string) {
    const domain = this.getConfigurationProperty('domain');
    const datasetUid = this.getConfigurationProperty('datasetUid');
    const url = `https://${domain}/${path}`;

    const headers = {
      Accept: 'application/json',
      'X-Socrata-Federation': 'Honey Badger',
      'X-App-Token': appToken()
    };

    const cacheKey = this.cacheKey(path, datasetUid);

    if (this.soqlRequestPromiseCache.hasOwnProperty(cacheKey)) {
      return this.soqlRequestPromiseCache[cacheKey];
    }

    const soqlGetRequestPromise = new Promise((resolve, reject) => {
      function handleError(jqXHR: any) {
        reject({
          status: parseInt(jqXHR.status, 10),
          message: jqXHR.statusText,
          soqlError: jqXHR.responseJSON || jqXHR.responseText || '<No response>'
        });
      }

      $.ajax({
        url,
        headers,
        method: 'GET',
        success: resolve,
        error: handleError
      });
    });

    this.soqlRequestPromiseCache[cacheKey] = soqlGetRequestPromise;

    return soqlGetRequestPromise;
  }

  // Returns a Promise for a POST against the given SoQL url.
  // On error, rejects with an object: {
  //   status: HTTP code,
  //   message: status text,
  //   soqlError: response JSON
  // }
  // TODO: Remove with soda3_viz
  private async makeSoqlPostRequest({ query }: { query: any }) {
    const domain = this.getConfigurationProperty('domain');
    const { datasetUid, ...options } = this.optionsForQuery();
    const url = `https://${domain}/api/id/${datasetUid}/query`;

    const headers = {
      Accept: 'application/json',
      'X-Socrata-Federation': 'Honey Badger',
      'X-App-Token': appToken(),
      'X-CSRF-Token': csrfToken()
    };

    const cacheKey = this.cacheKey(query, datasetUid);

    if (this.soqlRequestPromiseCache.hasOwnProperty(cacheKey)) {
      return this.soqlRequestPromiseCache[cacheKey];
    }

    const soqlPostRequestPromise = new Promise((resolve, reject) => {
      $.ajax({
        url,
        headers,
        method: 'POST',
        success: resolve,
        error: (response: any) =>
          reject({
            status: parseInt(response.status, 10),
            message: response.statusText,
            soqlError: response.responseJSON || response.responseText || '<No response>'
          }),
        contentType: 'application/json',
        data: JSON.stringify({ query, ...options })
      });
    });

    this.soqlRequestPromiseCache[cacheKey] = soqlPostRequestPromise;

    return soqlPostRequestPromise;
  }

  // TODO: Remove with soda3_viz
  private escapeColumnName(columnName: string) {
    return `\`${columnName}\``;
  }

  private optionsForQuery(viewId?: string) {
    return {
      datasetUid: viewId || this.getConfigurationProperty('datasetUid'),
      readFromNbe: this.getOptionalConfigurationProperty('readFromNbe', true),
      queryTimeout: this.getOptionalConfigurationProperty('queryTimeout', false),
      clientContextVariables: this.getOptionalConfigurationProperty('clientContextVariables', undefined)
    };
  }

  private resourceUri(format: 'json' | 'geojson', viewId?: string) {
    const { datasetUid } = this.optionsForQuery(viewId);
    return `/resource/${datasetUid}.${format}`;
  }

  // TODO: Remove with soda3_viz
  private pathForQuery(queryString: string, viewId?: string) {
    const { datasetUid, readFromNbe, queryTimeout } = this.optionsForQuery(viewId);

    let path = `api/id/${datasetUid}.json?${queryString}`;
    if (readFromNbe) {
      path = path + '&$$read_from_nbe=true&$$version=2.1';
    }

    if (queryTimeout) {
      path = path + '&$$query_timeout_seconds=' + queryTimeout;
    }

    if (this.hasClientContextVariables()) {
      path = path + '&' + toTypedOverrideParamString(this.getConfigurationProperty('clientContextVariables'));
    }

    return path;
  }

  private buildNumberOrDateRange(renderTypeName: string, min?: string | number, max?: string | number) {
    switch (renderTypeName) {
      case 'money':
      case 'number':
        return {
          rangeMin: _.toNumber(min),
          rangeMax: _.toNumber(max)
        };

      case 'calendar_date':
      case 'date':
        return {
          rangeMin: _.toString(min),
          rangeMax: _.toString(max)
        };
    }
  }

  private getFilterWhereConditions(filters: SoqlFilter[], columnName: string) {
    return _.chain(filters)
      .map((filterItem) => {
        const filterColumnName = (filterItem as SoqlFilter).columns[0].fieldName;

        if (filterColumnName === columnName) {
          return null;
        }
        return filterToWhereClauseComponent(filterItem);
      })
      .compact()
      .join(' AND ')
      .value();
  }

  private cacheKey(query: string, datasetUid: string): string {
    return `${query}_${datasetUid}`;
  }

  private hasClientContextVariables(): boolean {
    return this.getOptionalConfigurationProperty('clientContextVariables', [])?.length > 0;
  }

  private useSoda3Analyzer(): boolean {
    return ['all', 'common_viz_only'].includes(FeatureFlags.valueOrDefault('soda3_viz', 'none'));
  }
}

export default SoqlDataProvider;
