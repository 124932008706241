import React, { useEffect, useMemo, useState } from 'react';
import { ColDef, ColumnState, IServerSideDatasource } from '@ag-grid-community/core';
import { TableColumnFormat, RowFormat, FormatStyle } from 'common/authoring_workflow/reducers/types';
import { ViewColumn } from 'common/types/viewColumn';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { Vif, Hierarchy, OrderConfig } from 'common/visualizations/vif';
import { filter, get, isEmpty } from 'lodash';
import HierarchyTabs from './HierarchyTabs';
import Grid from './Grid';
import classNames from 'classnames';
import { Filters } from 'common/components/FilterBar/types';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import { Column } from '@ag-grid-community/core';
import { getParameterOverrides } from 'common/visualizations/helpers/VifSelectors';
import { RowStripeStyle } from 'common/types/agGrid/rowStripe';
import {
  DEFAULT_TABLE_HEADER_TEXT_COLOR,
  DEFAULT_TABLE_HEADER_BACKGROUND_COLOR
} from 'common/authoring_workflow/constants';
import { ExportData } from './types';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import eventBus from './helpers/EventBus';
import { AgColumnFilter } from 'common/types/agGrid/filters';

export interface AgGridContainerProps {
  vif: Vif;
  columnMetadata: ViewColumn[];
  nonStandardAggregations: ColumnAggregation[] | null;
  datasource: IServerSideDatasource;
  datasetName?: string;
  getGrandTotalRow: (hierarchyConfig: Hierarchy) => Promise<any>;
  onColumnReorder: (columnState: ColumnState[]) => void;
  onColumnResize: (columns: Column[] | null) => void;
  onColumnRowGroupChange: (columnState: ColumnState[], columns: Column[], hierarchyId?: string) => void;
  onColumnSort: (columnState: ColumnState[], hierarchyId?: string) => void;
  onColumnValueChange: (columns: Column[], hierarchyId?: string) => void;
  onColumnVisibilityChange: (
    columnState: ColumnState[],
    columns: Column[] | null,
    hierarchyId?: string
  ) => void;
  onHierarchyTabChange: (hierarchyId: string) => void;
  onFilterChange: (newFilters: Filters, agFilterModel?: AgColumnFilter) => void;
  paginationPageSize?: number;
  printMode?: boolean;
  pagination?: boolean;
  defaultColDefOverrides?: ColDef;
  displayColumnFilters?: boolean;
  useSetFilters?: boolean;
  agFilterModel?: AgColumnFilter;
  showAgGridColumnMenu?: boolean;
  showAgGridColumnAggregations?: boolean;
  isIndented?: boolean;
  initializeRowStripeStyle?: () => RowStripeStyle;
  openToolPanelByDefault?: boolean;
  vizUid?: string;
  getExportData?: (selectedFiltered: boolean) => Promise<ExportData>;
}

const GridContainer = (props: AgGridContainerProps) => {
  const {
    vif,
    columnMetadata,
    nonStandardAggregations,
    datasource,
    datasetName,
    getGrandTotalRow,
    onColumnReorder,
    onColumnResize,
    onColumnRowGroupChange,
    onColumnSort,
    onColumnValueChange,
    onColumnVisibilityChange,
    onHierarchyTabChange,
    onFilterChange,
    paginationPageSize,
    defaultColDefOverrides,
    displayColumnFilters,
    useSetFilters,
    agFilterModel,
    showAgGridColumnMenu,
    showAgGridColumnAggregations,
    initializeRowStripeStyle,
    openToolPanelByDefault,
    vizUid,
    getExportData
  } = props;

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  useEffect(() => {
    const onTogglePrintTable = () => setIsPrintModalOpen(true);
    eventBus.on(`print-table-${vizUid}`, onTogglePrintTable);

    return () => {
      eventBus.remove(`print-table-${vizUid}`, onTogglePrintTable);
    };
  }, [setIsPrintModalOpen]);

  const vifHierarchies: Hierarchy[] = get(vif, 'series[0].dataSource.hierarchies', []);
  const vifFilters: Filters = get(vif, 'series[0].dataSource.filters', []);
  const headerFormat: FormatStyle = get(vif, 'series[0].formatting.headerFormat', {});
  const rowFormat: RowFormat[] = get(vif, 'series[0].rowFormat', []);
  const vifParameterOverrides: ClientContextVariable[] = getParameterOverrides(vif);

  // Creates a map where the column 'fieldName' is the key and the value is a boolean field from the columns metadata which has the 'hidden' flag.
  const { columnFieldnameIsHiddenMap } = columnMetadata.reduce(
    (acc, columnMeta) => {
      acc.columnFieldnameIsHiddenMap[columnMeta.fieldName] = columnMeta.flags
        ? columnMeta.flags.includes('hidden')
        : false;
      return acc;
    },
    {
      columnFieldnameIsHiddenMap: {} as Record<string, boolean>
    }
  );

  const vifColumns: ViewColumn[] = useMemo(
    () =>
      get(vif, 'series[0].dataSource.dimension.columns', []).filter(
        (column: ViewColumn) => !columnFieldnameIsHiddenMap[column.fieldName]
      ),
    [vif, columnFieldnameIsHiddenMap]
  );
  const activeHierarchyId: string | undefined = get(vif, 'series[0].dataSource.activeHierarchyId');

  const isHierarchyActive = (hierarchy: Hierarchy) => {
    return hierarchy.id === activeHierarchyId;
  };

  const columnFormats: { [key: string]: TableColumnFormat } = get(
    props.vif,
    'series[0].formatting.columnFormat',
    {}
  );

  const commonGridProps = {
    columnMetadata,
    nonStandardAggregations,
    datasource,
    getGrandTotalRow,
    onColumnReorder,
    onColumnResize,
    onColumnRowGroupChange,
    onColumnSort,
    onColumnValueChange,
    onColumnVisibilityChange,
    onHierarchyTabChange,
    columnFormats,
    rowFormat: filter(rowFormat, ({ columnName }: RowFormat) => !isEmpty(columnName)),
    onFilterChange,
    vifFilters,
    vifParameterOverrides,
    vifColumns,
    title: get(props.vif, 'title'),
    description: get(props.vif, 'description'),
    pagination: get(props.vif, 'configuration.pagination'),
    domain: get(vif, 'series[0].dataSource.domain') || getDefaultDomain(),
    datasetUid: get(vif, 'series[0].dataSource.datasetUid'),
    datasetName,
    searchString: get(vif, 'series[0].dataSource.searchString'),
    paginationPageSize: get(vif, 'configuration.paginationPageSize', paginationPageSize),
    printMode: get(vif, 'configuration.printMode'),
    defaultColDefOverrides: get(vif, 'configuration.defaultColDefOverrides', defaultColDefOverrides),
    useSetFilters,
    agFilterModel,
    displayColumnFilters,
    showAgGridColumnMenu,
    showAgGridColumnAggregations,
    isIndented: get(vif, 'configuration.isIndented'),
    initializeRowStripeStyle,
    headerFormat,
    openToolPanelByDefault,
    vizUid,
    getExportData,
    activeHierarchyId
  };

  const isBoldHeaderFontStyle =
    headerFormat?.fontStyle?.isBold === undefined ? true : headerFormat.fontStyle.isBold;
  const isItalicHeaderFontStyle =
    headerFormat?.fontStyle?.isItalic === undefined ? false : headerFormat.fontStyle.isItalic;
  const style = {
    '--ag-header-foreground-color': headerFormat?.textColor || DEFAULT_TABLE_HEADER_TEXT_COLOR,
    '--ag-header-background-color': headerFormat?.backgroundColor || DEFAULT_TABLE_HEADER_BACKGROUND_COLOR,
    '--ag-table-header-font-weight': isBoldHeaderFontStyle ? 'bold' : 'normal',
    '--ag-table-header-font-style': isItalicHeaderFontStyle ? 'italic' : 'normal'
  };
  const gridClasses = classNames('ag-grid-react ag-theme-material', {
    'ag-grid-columns-aggregation': showAgGridColumnAggregations ?? true
  });
  return (
    <div className="ag-grid-visualization">
      <HierarchyTabs
        activeHierarchyId={activeHierarchyId}
        onTabChange={(newTabId) => props.onHierarchyTabChange(newTabId)}
        hierarchies={vifHierarchies}
        columns={props.columnMetadata}
      />
      {vifHierarchies.map((hierarchy) => {
        const vifOrderConfig: OrderConfig[] = hierarchy?.order ?? [];
        const agGridOpenNodeLevel = hierarchy?.agGridOpenNodeLevel ?? 0;

        const classes = classNames(gridClasses, { 'ag-hidden': !isHierarchyActive(hierarchy) });

        return (
          <div style={style} className={classes} key={hierarchy.id}>
            <Grid
              {...{ ...commonGridProps, vifOrderConfig, agGridOpenNodeLevel }}
              hierarchyConfig={hierarchy}
              isPrintModalOpen={isPrintModalOpen && activeHierarchyId === hierarchy.id}
              onPrintModalClose={() => setIsPrintModalOpen(false)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default GridContainer;
