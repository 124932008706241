import React from 'react';
import './export-dataset-modal.scss';
import {
  ForgeDialog,
  ForgeButton,
  ForgeIcon,
  ForgeScaffold,
  ForgeToolbar,
  ForgeIconButton,
  ForgeButtonToggle,
  ForgeButtonToggleGroup
} from '@tylertech/forge-react';
import { isEmpty, isString, isObject } from 'lodash';
import { FeatureFlags } from 'common/feature_flags';
import { View } from 'common/types/view';
import CopyToClipboard from 'react-copy-to-clipboard';
import { QueryCompilationSucceeded } from 'common/types/compiler';
import { getMimeType } from 'common/downloadLinks';
import { Option, none, some } from 'ts-option';
import UrlEndpoint from './UrlEndpoint';
import { fetchTranslation } from 'common/locale';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { checkStatus } from 'common/notifications/api/helper';
import contentDisposition from 'content-disposition';
import { ToastType, showToastNow, hideToastById } from '../ToastNotification/Toastmaster';
import { ExportStateFunctions, filterRowData, getTotalRowCount } from './ExportHelper';
import eventBus from 'common/visualizations/views/agGridReact/helpers/EventBus';
import { ResourceState, ResourceType } from './ExportModalTypes';
import FilteringRadioButtons, { FilterRadioButtonProps } from './FilteringRadioButtons';
import DownloadFileTab, { DownloadFileTabProps } from './DownloadFileTab';
import { getResourceState } from './ResourceStateHelper';

export const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.export_dataset_modal');

const toastHideId = 'export-modal-toast';

export enum TOGGLE_OPTIONS {
  DOWNLOAD_FILE = 'download_file',
  API_ENDPOINT = 'api_endpoint',
  ODATA_ENDPOINT = 'odata_endpoint'
}

export interface StateProps {
  query: Option<QueryCompilationSucceeded>;
  view: View;
  clientContextVariables: ClientContextVariable[];
}

export interface QueryStringObject {
  selectClause: string;
  odataSelectClause?: string;
  whereClause: string;
}

type Props = StateProps & {
  defaultToggleOption?: TOGGLE_OPTIONS;
  revisionSeq?: number;
  fourfour?: string;
  bodyText?: string;
  onDismiss: () => void;
  // Optional as you can use the query prop instead.
  // Or pass nothing and it will export all the data on the view
  queryStringClause?: string | QueryStringObject;
  showDataToggles?: boolean;
  // Used by when showDataToggles is true. Pass this in if you have it or the dialog will fetch it.
  totalRowCount?: number;
  // used by API Endpoint
  apiFoundryUrl?: string;
  vizUid?: string;
  isTableViz: boolean | false;
  isExportModalOpen?: boolean;
};

export interface State {
  toggleOption: TOGGLE_OPTIONS;
  resourceState: ResourceState;
  rowCountFiltered?: number;
  rowCountAll?: number;
  selectedFiltered: boolean;
  showFilteredDataOption: boolean;
  useResourceName: boolean;
  isExportModalOpen: boolean;
}

export default class ExportModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      toggleOption: this.props.defaultToggleOption || TOGGLE_OPTIONS.DOWNLOAD_FILE,
      resourceState: getResourceState(
        props.view,
        props.query,
        props.clientContextVariables,
        none,
        props.isTableViz,
        props.fourfour,
        props.revisionSeq
      ),
      rowCountFiltered: 0,
      rowCountAll: 0,
      selectedFiltered: this.shouldShowFilteredDownload(),
      showFilteredDataOption: this.shouldShowFilteredDownload(),
      useResourceName: false,
      // Setting isExportModalOpen to the old default value = TRUE
      isExportModalOpen: this.props.isExportModalOpen ?? true
    };
  }

  componentDidMount() {
    const { view, totalRowCount, showDataToggles, queryStringClause } = this.props;
    const queryString = isObject(queryStringClause)
      ? `${queryStringClause.selectClause} ${queryStringClause.whereClause}`
      : queryStringClause;
    if (queryString) {
      this.updateResourceStateQueryString();

      const exportStateFunctions: ExportStateFunctions = {
        setRowCountFiltered: this.setRowCountFiltered
      };

      filterRowData(view, exportStateFunctions, queryString);
    }

    // Set the total row count for the toggles
    if (showDataToggles) {
      if (totalRowCount) {
        this.setRowCountAll(totalRowCount);
      } else {
        getTotalRowCount(view, this.setRowCountAll);
      }
    }
  }

  setRowCountAll = (rowCount: number) => {
    this.setState({ rowCountAll: rowCount });
  };

  setRowCountFiltered = (rowCount: number) => {
    this.setState({ rowCountFiltered: rowCount });
  };

  handleToggleChange = (value: string) => {
    this.setState({ toggleOption: value as TOGGLE_OPTIONS });
  };

  handleRadioFilterChange = (selectedFiltered: boolean) => {
    this.setState({ selectedFiltered: selectedFiltered }, () => this.updateResourceStateQueryString());
  };

  handleUseResourceNameChange = (useName: boolean) => {
    this.setState({ useResourceName: useName }, () => this.updateResourceStateQueryString());
  };

  updateResourceStateQueryString() {
    const { queryStringClause } = this.props;
    const odataQueryString = isObject(queryStringClause) ? queryStringClause.odataSelectClause : undefined;
    let queryString;
    // We should only be able to select filtered when there is a queryString
    if (this.state.selectedFiltered && queryStringClause) {
      // Here we are exporting filtered
      queryString = isString(queryStringClause)
        ? some(queryStringClause)
        : some(`${queryStringClause.selectClause} ${queryStringClause.whereClause}`);
    } else {
      // If the export is on a viz, then respect the column selection
      queryString = isObject(queryStringClause) ? some(queryStringClause.selectClause) : none;
    }
    this.setState({
      resourceState: getResourceState(
        this.props.view,
        this.props.query,
        this.props.clientContextVariables,
        queryString,
        this.props.isTableViz,
        this.props.fourfour,
        this.props.revisionSeq,
        this.state.resourceState,
        this.state.useResourceName,
        odataQueryString
      )
    });
  }

  onDownload = () => {
    hideToastById(toastHideId);
    if (this.state.resourceState.selectedDownloadResource) {
      showToastNow({
        type: ToastType.FORGE_DEFAULT,
        content: t('data_exported'),
        timeout: Infinity,
        hideId: toastHideId
      });
      if (this.state.resourceState.selectedDownloadResource?.value === 'xlsx') {
        eventBus.dispatch(`exportGridData-${this.props.vizUid}`, {
          selectedFiltered: this.state.selectedFiltered
        });
        this.hideModal();
      } else {
        this.downloadFileRequest(this.state.resourceState.selectedDownloadResource);
      }
    }
  };

  onCopyToast = (content: string) => {
    showToastNow({
      type: ToastType.FORGE_DEFAULT,
      content,
      hideId: toastHideId
    });
  };

  onCopyApiToast = () => this.onCopyToast(t('api_copied'));
  onCopyOdataToast = () => this.onCopyToast(t('odata_copied'));

  filteringRadioButtons = (odata = false) => {
    const { rowCountFiltered, rowCountAll, resourceState, selectedFiltered, showFilteredDataOption } = this.state;

    if (!this.props.showDataToggles) return null;

    const filteringRadioButtonProps: FilterRadioButtonProps = {
      resourceState: resourceState,
      selectedFiltered: selectedFiltered,
      handleRadioFilterChange: this.handleRadioFilterChange,
      showFilteredDataOption: showFilteredDataOption,
      rowCountFiltered: rowCountFiltered,
      rowCountAll: rowCountAll,
      odata: odata
    };

    return <FilteringRadioButtons {...filteringRadioButtonProps} />;
  };

  // we only want to show the warning when currently export is over 1000
  shouldShowApiWarning() {
    if (this.state.selectedFiltered) {
      return (this.state.rowCountFiltered || 0) > 1000;
    } else {
      return (this.state.rowCountAll || 0) > 1000;
    }
  }

  apiEndpoint = () => {
    return (
      <div>
        <UrlEndpoint
          onCopy={this.onCopyApiToast}
          onSelectedUrlChange={this.handleSelectedUrlChange}
          resources={this.state.resourceState.apiResources}
          selectedResource={this.state.resourceState.selectedApiResource}
          foundryUrl={this.props.apiFoundryUrl}
          filteringRadioButtons={this.filteringRadioButtons()}
          useResourceName={this.state.useResourceName}
          onResourceNameUpdate={
            // we only want to show the option to add resource name when there is one available
            this.props.view?.resourceName ? this.handleUseResourceNameChange : undefined
          }
          showApiLimitWarning={this.shouldShowApiWarning()}
          apiOrOdata="api"
          isOdata={false}
        />
      </div>
    );
  };

  _OdataEndpoint = () => {
    return (
      <div>
        <UrlEndpoint
          onCopy={this.onCopyOdataToast}
          onSelectedUrlChange={this.handleOdataVersionChange}
          resources={this.state.resourceState.odataResources}
          selectedResource={this.state.resourceState.selectedOdataResource}
          filteringRadioButtons={this.filteringRadioButtons(true)}
          useResourceName={this.state.useResourceName}
          onResourceNameUpdate={
            // we only want to show the option to add resource name when there is one available
            this.props.view?.resourceName ? this.handleUseResourceNameChange : undefined
          }
          showApiLimitWarning={false}
          apiOrOdata="odata"
          isOdata={true}
        />
      </div>
    );
  };

  downloadFile = () => {
    const { downloadResources, selectedDownloadResource } = this.state.resourceState;
    const downloadProps: DownloadFileTabProps = {
      downloadResources: downloadResources,
      selectedDownloadResource: selectedDownloadResource,
      handleExportChange: this.handleExportChange,
      filteringRadioButtons: this.filteringRadioButtons()
    };

    return (
      <DownloadFileTab {...downloadProps} />
    );
  };

  handleExportChange = (url: string) => {
    this.setState((prevState) => ({
      resourceState: {
        ...prevState.resourceState,
        selectedDownloadResource: prevState.resourceState.downloadResources.find((v) => v.url === url)
      }
    }));
  };

  handleSelectedUrlChange = (resource: ResourceType) => {
    this.setState((prevState) => ({
      resourceState: {
        ...prevState.resourceState,
        selectedApiResource: resource
      }
    }));
  };

  handleOdataVersionChange = (resource: ResourceType) => {
    this.setState((prevState) => ({
      resourceState: {
        ...prevState.resourceState,
        selectedOdataResource: resource
      }
    }));
  };

  downloadFileRequest = (downloadResource: ResourceType) => {
    const save: HTMLAnchorElement = document.createElement('a');

    fetch(downloadResource.url, {
      method: 'GET',
      credentials: 'same-origin'
    })
      .then((r) => checkStatus(r, `Error exporting data in format ${downloadResource.value}`))
      .then((response) => {
        // gets the filename from Content-Disposition (which is not always there)
        // when the filename is not provided we typically open the file in browser
        const contentHeaders = response.headers.get('Content-Disposition');
        if (contentHeaders) {
          const filename = contentDisposition.parse(contentHeaders)?.parameters?.filename;
          // Browser will assign a guid for name if filename is missing (shouldn't happen but possible)
          save.download = filename || '';
        } else if (downloadResource.value === 'rdf') {
          // rdf is a special case where we don't get a name for it
          // from the server and its not just opened in browser
          save.download = 'rows.rdf';
        }
        return response.blob();
      })
      .then((blob) => {
        const contentType = getMimeType(downloadResource.value);
        const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
        save.target = '_blank';
        save.rel = 'noreferrer';
        save.setAttribute('style', 'display: none');
        document.body.appendChild(save);
        save.href = url;
        save.click();
        // delay to avoid race condition where browser could not load URL
        // before it was revoked
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 200);
        hideToastById(toastHideId);
        this.hideModal();
      })
      .catch((error) => {
        console.error('Error attempting export request', error);
        showToastNow({
          type: ToastType.FORGE_ERROR,
          content: t('export_error'),
          timeout: Infinity,
          hideId: toastHideId
        });
      });
  };

  shouldShowFilteredDownload() {
    const { queryStringClause } = this.props;
    return isObject(queryStringClause)
      ? !isEmpty(queryStringClause.whereClause)
      : !isEmpty(queryStringClause);
  }

  shouldRenderOdataOption() {
    const isStoryteller = !isEmpty(window.STORY_DATA);
    const enableOdataEndpoint = FeatureFlags.valueOrDefault('enable_endpoints_in_stories') !== 'none';
    return isStoryteller && enableOdataEndpoint;
  }

  shouldRenderApiOption() {
    const isStoryteller = !isEmpty(window.STORY_DATA);
    const enableApiEndpoint = FeatureFlags.valueOrDefault('enable_endpoints_in_stories') === 'odata_api';
    return !isStoryteller || enableApiEndpoint;
  }

  shouldRenderExportOptions() {
    const isStoryteller = !isEmpty(window.STORY_DATA);
    const enableOptions = FeatureFlags.valueOrDefault('enable_endpoints_in_stories') !== 'none';
    return !isStoryteller || enableOptions;
  }

  hideModal = () => {
    // A memory leak occurs when triggering `props.onDismiss()` due to an async task in the Forge Overlay.
    // Path: node_modules/.pnpm/@tylertech+forge-react@2.3.0_@tylertech+forge@2.24.1_react@17.0.2/node_modules/@tylertech/forge-react/dist/forge-react.js
    // We need to wait for the async task to complete before calling `props.onDismiss()`.
    const MODAL_DISMISS_DELAY = 300;
    this.setState({ isExportModalOpen: false }, () => {
      setTimeout(() => {
        this.props.onDismiss();
      }, MODAL_DISMISS_DELAY);
    });
  };

  render() {
    const { toggleOption } = this.state;
    const apiResourceUrl = this.state.resourceState.selectedApiResource.url;
    const odataResourceUrl = this.state.resourceState.selectedOdataResource.url;

    const dialogAttributes = new Map([
      ['aria-labelledby', 'export-dialog-title'],
      ['aria-describedby', 'export-dialog-body']
    ]);


    return (
      <ForgeDialog
        onDismiss={this.hideModal}
        open={this.state.isExportModalOpen}
        options={{ backdropClose: false, dialogAttributes }}
      >
        <div className="export-dataset-dialog">
          <ForgeScaffold>
            <div slot="header">
              <ForgeToolbar>
                <h1
                  slot="start"
                  id="export-dialog-title"
                  className="forge-typography--title forge-header-title"
                >
                  {t('export_dataset')}
                </h1>
                <ForgeIconButton slot="end">
                  <button data-testid="export-modal-x" className="tyler-icons" onClick={this.hideModal} aria-label={t('close_dialog')}>
                    <ForgeIcon name="close" />
                  </button>
                </ForgeIconButton>
              </ForgeToolbar>
            </div>

            <div slot="body" className="export-dialog-container">
              <div>
                <p id="export-dialog-body" className="forge-typography--body2 body-text">
                  {this.props.bodyText}
                </p>
              </div>
              <div>
                {this.shouldRenderExportOptions() ? (
                  <ForgeButtonToggleGroup
                    id="export-button-group"
                    data-testid="export-toggle-buttons"
                    className="button-toggle"
                    mandatory={true}
                    value={toggleOption}
                    on-forge-button-toggle-group-change={(event: CustomEvent) =>
                      this.handleToggleChange(event.detail)
                    }
                  >
                    <ForgeButtonToggle
                      data-testid="export-toggle-download"
                      value={TOGGLE_OPTIONS.DOWNLOAD_FILE}
                      aria-label={t('download_file')}
                      forge-dialog-focus="true"
                    >
                      {t('download_file')}
                    </ForgeButtonToggle>
                    {this.shouldRenderApiOption() ? (
                      <ForgeButtonToggle
                        data-testid="export-toggle-api"
                        value={TOGGLE_OPTIONS.API_ENDPOINT}
                        aria-label={t('api_endpoint')}
                      >
                        {t('api_endpoint')}
                      </ForgeButtonToggle>
                    ) : null}
                    {this.shouldRenderOdataOption() ? (
                      <ForgeButtonToggle
                        data-testid="export-toggle-odata"
                        value={TOGGLE_OPTIONS.ODATA_ENDPOINT}
                        aria-label={t('odata_endpoint')}
                      >
                        {t('odata_endpoint')}
                      </ForgeButtonToggle>
                    ) : null}
                  </ForgeButtonToggleGroup>
                ) : null}
              </div>
              <div className="export-formats">
                {toggleOption === TOGGLE_OPTIONS.ODATA_ENDPOINT && this._OdataEndpoint()}
                {toggleOption === TOGGLE_OPTIONS.API_ENDPOINT && this.apiEndpoint()}
                {toggleOption === TOGGLE_OPTIONS.DOWNLOAD_FILE && this.downloadFile()}
              </div>
            </div>

            <div slot="footer" className="footer">
              <ForgeToolbar inverted={true}>
                <ForgeButton slot="end" type="outlined">
                  <button data-testid="export-modal-cancel" onClick={this.hideModal}>
                    {t('cancel')}
                  </button>
                </ForgeButton>

                {this.state.toggleOption === TOGGLE_OPTIONS.DOWNLOAD_FILE && (
                  <ForgeButton slot="end" type="raised">
                    <button
                      data-testid="export-download-button"
                      onClick={this.onDownload}
                      disabled={!this.state.resourceState.selectedDownloadResource?.url}
                    >
                      {t('download')}
                    </button>
                  </ForgeButton>
                )}
                {this.state.toggleOption === TOGGLE_OPTIONS.API_ENDPOINT && (
                  <CopyToClipboard text={apiResourceUrl}>
                    <ForgeButton slot="end" type="raised">
                      <button
                        data-testid="api-copy-button"
                        onClick={() => {
                          this.onCopyApiToast();
                          this.hideModal();
                        }}
                        disabled={!apiResourceUrl}
                      >
                        {t('copy_clipboard')}
                      </button>
                    </ForgeButton>
                  </CopyToClipboard>
                )}
                {this.state.toggleOption === TOGGLE_OPTIONS.ODATA_ENDPOINT && (
                  <CopyToClipboard text={odataResourceUrl}>
                    <ForgeButton slot="end" type="raised">
                      <button
                        data-testid="odata-copy-button"
                        onClick={() => {
                          this.onCopyOdataToast();
                          this.hideModal();
                        }}
                        disabled={!odataResourceUrl}
                      >
                        {t('copy_clipboard')}
                      </button>
                    </ForgeButton>
                  </CopyToClipboard>
                )}
              </ForgeToolbar>
            </div>
          </ForgeScaffold>
        </div>
      </ForgeDialog>
    );
  }
}
